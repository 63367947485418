.members-page-main-div {
    background-color: #1d1d1f;
    min-height: 45rem;
    position: relative;
    padding: 5rem 6%;
}

.membercard-main {
    background-color: #181818;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    border: none;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.479));
    margin-bottom: 2rem;
}

.description-animo-div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 15.5rem;
    border-radius: 25px;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
    z-index: 2;
    padding: 1.3rem 1.3rem;
    background-color: #181818;
    color: white;
}

.membercard-blue-banner {
    height: 6rem;
    width: 100%;
    position: absolute;
    border-radius: 26px 26px 0 0;
    top: 0;
    border: none;
}

.membercard-pfp {
    width: 7.5rem;
    height: 7.5rem;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid white;
    background-color: white;
    background-image: url(../Partials/Images/double-spinners.svg);
    background-size: 4rem;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    position: relative;
    display: block;
    margin: 2rem auto 1rem;
    transition: all 0.3s ease-in-out;
}

.membercard-name {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
}

.membercard-role {
    text-align: center;
    font-weight: 600;
    color: #9e9e9e;
}

.membercard-content-holder {
    padding: 0 0.5rem;
}

.membercard-animocard-description {
    font-size: 0.9rem;
}

.fireflies-bg {
    position: absolute;
    top: 0;
    width: 50rem;
    opacity: 0.2;
    width: 100%;
    object-fit: cover;
}