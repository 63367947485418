.home-footer-main-div {
    min-height: 40rem;
    background-color: #181818;
    padding: 5rem 7%;
    color: white;
}

.fsoc-footer-logo {
    width: 12rem;
    margin-bottom: 2rem;
}

.footer-p {
    color: #9E9E9E;
    font-size: 2rem;
}

.footer-social-media {
    height: 3rem;
}

.footer-navlink {
    transition: all 0.3s ease-in-out;
    font-size: 1.5rem;
}

.footer-navlink:hover {
    text-decoration: none;
    color: white;
}

.footer-dot-separator {
    font-size: 0.4rem;
    vertical-align: middle;
}

.footer-contact {
    font-size: 1.2rem;
}

.footer-contact-anchor {
    transition: all 0.3s ease-in-out;
}

.footer-contact-anchor:hover {
    text-decoration: none;
}

.footer-end {
    font-size: 1.5rem;
}

@media (max-width: 576px) {
    .footer-contact-anchor {
        display: block;
        margin-bottom: 1rem;
    }

    .footer-navlink {
        font-size: 1.3rem;
    }
}