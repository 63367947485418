body {
    background-color: rgb(228, 228, 228);
    font-family: Roboto;
    padding: 0;
    position: relative;
}

html, body {
    overflow-x: hidden;
  }
  body {
    position: relative;
    overflow-y: hidden;
  }

.tcol-themeblue {
    color: #566FFF;
}

.bg-themeblue {
    background-color: #566FFF;
}

.tcol-white {
    color: white;
}

.tcol-black {
    color: black;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.fw-900 {
    font-weight: 900;
}

.fs-1-1 {
    font-size: 1.1rem;
}

.fs-1-2 {
    font-size: 1.2rem;
}

.fs-1-3 {
    font-size: 1.3rem;
}

.fs-1-4 {
    font-size: 1.4rem;
}

.fs-1-5 {
    font-size: 1.5rem;
}

.hr-pale {
    border-color: rgba(255, 255, 255, 0.164);
}

.tcol-pale {
    color: #9E9E9E;
}