.original-works-main-div {
    min-height: 45rem;
    background-color: #1D1D1F;
    padding: 5rem 7%;
}

.whatwedo {
    font-size: 2.5rem;
    font-weight: 600;
}

.whatwedo-content {
    font-size: 1.5rem;
}