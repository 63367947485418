.landing-div-main {
    background-color: #181818;
    min-height: 45rem;
}

.landing-topleft {
    position: absolute;
    z-index: 1;
    top: -3rem;
    left: -1rem;
    width: 38rem;
}

.landing-bottomright {
    position: absolute;
    z-index: 1;
    right: 0;
    width: 38rem;
    bottom: 0;
    filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.4));
}

/*******LANDING LEFT********/

.landing-left-div {
    padding: 13rem 0 10.4rem;
    padding-left: 7rem;
}

.landing-h1 {
    color: white;
    font-size: 6rem;
    font-weight: 700;
}

.landing-h2 {
    color: #566FFF;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.landing-h4 {
    color: #9E9E9E;
    font-size: 2.1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.landing-btn {
    background-color: #566FFF;
    color: white;
    font-size: 1.6rem;
    border-radius: 15px;
    padding: 0.3rem 1rem;
    transition: background-color 0.3s ease-in-out
}

.landing-btn:hover {
    color: white;
    background-color: #181818;
    border: 1px solid #566FFF;
}

.landing-clapboard {
    width: 33rem;
    margin-top: 1.5rem;
    animation: float 4s infinite;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .landing-h1 {
        font-size: 3.8rem;
    }
    
    .landing-h2 {
        font-size: 2.5rem;
    }
    
    .landing-h4 {
        font-size: 1.3rem;
    }

    .landing-left-div {
        padding-left: 0;
        padding-top: 14rem;
        text-align: center;
    }

    .landing-bottomright, .landing-topleft {
        max-width: 100%;
    }

    .landing-div-main {
        min-height: 45rem;
    }

    .landing-btn {
        font-size: 1.4rem;
    }
}

@media (max-width: 1200px) {
    .landing-clapboard {
        display: none;
    }
}

@keyframes float {
    0% {
        transform: translateY(-15px);
    }
    50% {
        transform: translateY(15px);
    }
    100% {
        transform: translateY(-15px);
    }
}

.homepage-anim-bg {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 45rem;
    opacity: 0.35;
}